.review-container {
  background: white;
  width: 800px;
  min-height: 200px;
  margin-left: 10%;
  margin-top: 50px;
  margin-right: 5vw;
  border-radius: 50px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.name {
  position: absolute;
  left: 50px;
  top: 15px;
}

.grade {
  position: absolute;
  right: 350px;
  top: 15px;
  color: red;
  font-family: "Times New Roman", Times, serif;
}

.yeartaken {
  position: absolute;
  margin: 50px;
}
.review-description {
  margin-top: 100px;
  margin-left: 100px;
  margin-right: 100px;
}

.review {
  margin: 30px 100px;
}

.port-loading::after {
  content: "";
  position: absolute;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: rgba(239, 124, 0, 1);
  animation: juicy-loader 1.5s ease-in-out infinite;
  margin-left: 25vw;
  margin-top: 25vh;
}

.red-x-button {
  position: absolute;
  top: 20px;
  right: 30px;
}

.red-x-button {
  width: 40px;
  height: 40px;
  cursor: pointer;
  font-size: 20px;
  color: #ffffff;
  background-color: #ff0000;
  border: none;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.red-x-button:hover {
  background-color: #cc0000;
}

.red-x-button:focus {
  outline: none;
}

.red-x-button:active {
  background-color: #aa0000;
}

.edit-button {
  position: absolute;
  top: 25px;
  right: 100px;
}

.edit-button {
  padding: 5px 15px;
  font-size: 12px;
  font-weight: bold;
  color: #ffffff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.edit-button:hover {
  background-color: #0056b3;
}

.edit-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px #007bff;
}
