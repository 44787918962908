.portfolio-form {
  position: absolute;
  min-height: 400px;
  width: 800px;
  background-color: wheat;
  z-index: 5;
  left: 30%;
  top: 30%;
  border-radius: 50px;
}

.name-div {
  margin-left: 80px;
  margin-bottom: 50px;
}

.label {
  margin-right: 15px;
}

.input {
  border-radius: 10px;
}

.red-cross-button {
  display: inline-block;
  width: 30px;
  height: 30px;
  background-color: #f44336;
  border: none;
  border-radius: 50%;
  font-size: 16px;
  color: #fff;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left: 93%;
  margin-top: 10px;
}

.red-cross-button:hover {
  background-color: #d32f2f;
}

.red-cross-button:focus {
  outline: none;
}

.red-cross-button:active {
  background-color: #b71c1c;
}

.portfolio-form {
  opacity: 1;
}

.descrip-div {
  margin-left: 50px;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
}

.review-div {
  margin-left: 80px;
  display: flex;
  align-items: center;
}

.grade-div {
  margin-left: 90px;
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}

.portfolio-form {
  display: flex;
  flex-direction: column;
}

.selects {
  display: flex;
  flex-direction: row;
}

.year-div {
  display: flex;
  align-items: center;
  margin-left: 50px;
  margin-bottom: 53px;
}
