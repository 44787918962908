article {
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

/* CSS styling for the profile picture */
.profile-pic {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ddd;
}

.profile-pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  background: url("https://www.google.com/url?sa=i&url=https%3A%2F%2Fnaruto.fandom.com%2Fwiki%2FSage&psig=AOvVaw1-hr1aP4dZfFM53IKwt2Cg&ust=1687614521734000&source=images&cd=vfe&ved=0CBEQjRxqFwoTCLi28JDE2f8CFQAAAAAdAAAAABAJ");
}

/* CSS styling for the name */
.profile-name {
  font-size: 24px;

  margin-top: 10px;
}

/* CSS styling for the year */
.year {
  font-size: 18px;
  margin-top: 5px;
}

@keyframes juicy-loader {
  0% {
    transform: scale(0.4);
    opacity: 0.2;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.4);
    opacity: 0.2;
  }
}

/* Apply the loading animation to the article container */
div.loading {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Style the loading animation element */
.loading::after {
  content: "";
  position: absolute;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: rgba(239, 124, 0, 1);
  animation: juicy-loader 1.5s ease-in-out infinite;
  margin-top: 200px;
}

.modules {
  margin-top: 10px;
}
