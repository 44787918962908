.user-list {
  width: 400px;
  height: 100px;
  background: white;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 100px;
  margin-top: 100px;
}
