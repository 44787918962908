.search-bar {
  display: flex;
  align-items: center;
  width: 400px;
  margin: 0 auto;
  background-color: #fff;
  border: 1px solid #e1e5e8;
  border-radius: 24px;
  overflow: hidden;
  transition: box-shadow 0.3s ease;
}

.search-bar:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.search-bar input {
  flex-grow: 1;
  height: 40px;
  padding: 8px 16px;
  border: none;
  font-size: 14px;
  background-color: transparent;
  outline: none;
}

.search-bar input::placeholder {
  color: #9da0a4;
}

.search-bar button {
  height: 40px;
  width: 40px;
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  margin-top: 5px;
}

.search-bar button:focus {
  outline: none;
}

.search-bar button:hover .magnifying-glass {
  color: black;
}

.search-bar button {
  color: #9da0a4;
  font-size: 18px;
  transition: color 0.3s ease;
}

.search-bar input:focus {
  flex-grow: 1;
  height: 40px;
  padding: 8px 16px;
  border: none;
  font-size: 14px;
  background-color: transparent;
  outline: none;
}

input[type="text"] {
  transition: all 0.3 ease-in-out;
  border: #dddddd;
}

input[type="text"]:focus {
  border: rgba(81, 203, 238, 1);
  box-shadow: rgba(81, 203, 238, 1);
}
