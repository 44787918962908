.port-loading {
  height: 100px;
  width: 100px;
}

/* Add button */

.add-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 50%;
  font-size: 40px;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 40px;
  margin-left: 60px;
}

.add-button:hover {
  background-color: #45a049;
}

.add-button:focus {
  outline: none;
}

.add-button:active {
  background-color: #3e8e41;
  transform: translateY(1px);
}

.add-button p {
  margin-bottom: 10px;
}

.container {
  position: relative;
}
/* add review button */
.add-review-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #2196f3;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left: 80%;
  margin-top: 100px;
}

.add-review-button:hover {
  background-color: #1976d2;
}

.add-review-button:focus {
  outline: none;
}

.add-review-button:active {
  background-color: #1565c0;
}

select {
  display: block;

  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

select option {
  font-size: 16px;
}

textarea {
  min-height: 100px;
  width: 350px;
}
