.port-loading {
  height: 100px;
  width: 100px;
}

.container {
  position: relative;
}
/* chat button */
.chat-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #2196f3;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left: 80%;
  margin-top: 100px;
}

.chat-button:hover {
  background-color: #0056b3;
}
